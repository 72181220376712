







































































































































































































































































































































.integration-page {
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      width: 16px;
      color: #5369f8;
    }
  }
}
